<template>
<div class="row-space-tbf" v-if="loaded">
	<div class="space-left"><div v-if="$resize && $mq.above(780)" class="go-back" @click="$router.push({ name: 'retentions' })"><icon-arrow-back /></div></div>
	<div class="content opacity-page">
			<div class="header-form">
				<h1 class="title">{{ $route.params.id ? $t('salary.retention.title_edit') : $t('salary.retention.title_new')}}</h1>
				<div class="actions">
					<button class="btn-tbf white only-icon" @click="$router.push({ name: 'retentions' })"><icon-close class="icon-close" /></button>
				</div>
			</div>
			<show-contract v-if="userData" :data="userData" />
			<choose-contract-multiple v-if="!$route.params.id && !$route.params.contract_id" :contract="retention" :validator="$v.retention"/>
			<div class="form-group">
				<div class="data-group">
					<div class="title">{{ $t('salary.retention.title') }}</div>
				</div>
				<div class="form">
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.retention.type') }}*</label>
							<div v-if="$v.retention.selectedRetention.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.retention.selectedRetention.$error}">
							<div class="icon-left"><icon-retention /></div>
							<div class="icon-right" v-if="retention.selectedRetention != ''" @click.stop="retention.selectedRetention = '', retention.name = '', retention.selectedRetentionBase = ''"><icon-close class="icon-clear" /></div>
							<multiselect 
								v-model="retention.selectedRetention"
								v-bind:class="{populate: retention.selectedRetention != ''}"
								class="select-tags-tbf"
								:options="retentionsOptions"
								:allow-empty="true"
								:show-labels="false"
								track-by="id" 
								label="name"
                                @input="changeInputValues()"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('salary.retention.type-ph') }}
									</span>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.retention.name') }}*</label>
                            <div v-if="$v.retention.name.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.retention.name.$error}">
							<div class="icon-left"><icon-mobile /></div>
							<input type="text" class="input-text" :placeholder="$t('salary.retention.name-ph')" v-model="retention.name">
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.retention.start_date') }}*</label>
							<div v-if="$v.retention.start_date.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box"  v-bind:class="{has_error: $v.retention.start_date.$error}">
							<div class="icon-left"><icon-calendar /></div>
							<div class="icon-right" v-if="Object.keys(retention.start_date).length" @click.stop="retention.start_date = {}"><icon-close class="icon-clear" /></div>
							<FunctionalCalendar
								ref="CalendarDateOfEmployment"
								v-model="retention.start_date"
								class="calendar-tbf"
								:configs="calendarConfigs"
								@input="changeEndDate()"
							>
							<template v-slot:datePickerInput="props">
								<input
								class="vfc-single-input custom-input-picker"
								type="text"
								:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
								v-if="Object.keys(retention.start_date).length"/>
								<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.suspension.start_date-ph') }}</div>
							</template>
							</FunctionalCalendar>
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.retention.end_date') }}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-calendar /></div>
							<div class="icon-right"  v-if="Object.keys(retention.end_date).length" @click.stop="retention.end_date = {}"><icon-close class="icon-clear" /></div>
							<FunctionalCalendar
								ref="CalendarDateOfEmployment"
								v-model="retention.end_date"
								class="calendar-tbf"
								:configs="calendarConfigsEndData"
							>
							<template v-slot:datePickerInput="props">
								<input
								class="vfc-single-input custom-input-picker"
								type="text"
								:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
								v-if="Object.keys(retention.end_date).length"/>
								<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.retention.end_date-ph') }}</div>
							</template>
							</FunctionalCalendar>
						</div>
					</div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                        <div class="label-header">
                            <label class="label">{{ $t('salary.retention.is_taxable') }}</label>
                        </div>
                        <div class="input-box bor-0">
                            <div class="checkbox-list">
                                <div class="checkbox-item m-4" v-bind:class="{active: retention.selectedRetention.is_taxable === 0}">
                                    <div class="name">{{ $t('create-user.no') }}</div>
                                </div>
                                <div class="checkbox-item m-4" v-bind:class="{active: retention.selectedRetention.is_taxable === 1}">
                                    <div class="name">{{ $t('create-user.yes') }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                        <div class="label-header">
                            <label class="label">{{ $t('salary.retention.retention_cass') }}</label>
                        </div>
                        <div class="input-box bor-0">
                            <div class="checkbox-list">
                                <div class="checkbox-item m-4" v-bind:class="{active: retention.selectedRetention.retain_from_CAS_CASS === 0}">
                                    <div class="name">{{ $t('create-user.no') }}</div>
                                </div>
                                <div class="checkbox-item m-4" v-bind:class="{active: retention.selectedRetention.retain_from_CAS_CASS === 1}">
                                    <div class="name">{{ $t('create-user.yes') }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.benefits.calcul-type') }}*</label>
							<div v-if="$v.retention.selectedPercentage.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.retention.selectedPercentage.$error}">
							<div class="icon-left"><icon-calculator /></div>
							<!-- <div class="icon-right" v-if="retention.selectedPercentage != ''" @click.stop="retention.selectedPercentage = ''"><icon-close class="icon-clear" /></div> -->
							<multiselect 
								v-model="retention.selectedPercentage"
								v-bind:class="{populate: retention.selectedPercentage != ''}"
								class="select-tags-tbf"
								:options="percentageOptions"
								:allow-empty="true"
								:show-labels="false"
								track-by="id" 
								label="name"
                                @input="retention.value = ''"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('salary.benefits.calcul-type-ph') }}
									</span>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>
					<div v-if="retention.selectedPercentage.id == 1" class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.retention.percentage') }}*</label>
							<div v-if="$v.retention.value.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.retention.value.$error}">
							<div class="icon-left"><icon-calculator /></div>
							<input type="text" class="input-text" :placeholder="$t('salary.retention.percentage-ph')" v-model="retention.value">
						</div>
					</div>
                    <div v-if="retention.selectedPercentage.id == 2" class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.retention.fix_sum') }}*</label>
							<div v-if="$v.retention.value.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.retention.value.$error}">
							<div class="icon-left"><icon-calculator /></div>
							<input type="number" class="input-text" :placeholder="$t('salary.retention.fix_sum-ph')" v-model="retention.value">
						</div>
					</div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.retention.retention_base') }}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-retention /></div>
							<div class="icon-right" v-if="retention.selectedRetentionBase != ''" @click.stop="retention.selectedRetentionBase = ''"><icon-close class="icon-clear" /></div>
							<multiselect 
								v-model="retention.selectedRetentionBase"
								v-bind:class="{populate: retention.selectedRetentionBase != ''}"
								class="select-tags-tbf"
								:options="retentionsBasesOptions"
								:allow-empty="true"
								:show-labels="false"
								track-by="id" 
								label="name"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('salary.retention.retention_base-ph') }}
									</span>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="space-right"></div>
		<div class="form-submit crud">
			<div class="error-msg" v-show="error_message != ''">{{error_message}}</div>
			<button class="btn-tbf grey" @click="$router.push({ name: 'retentions' })"><span class="text">{{ $t('general.cancel') }}</span></button>
			<button id="buttonCreate" class="btn-tbf blue" @click="saveAction('create')" v-if="!$route.params.id">
				<div class="loader"></div>
				<span class="text">{{ $t('general.add') }}</span>
			</button>
			<button id="buttonUpdate" class="btn-tbf blue" @click="saveAction('update')" v-else>
				<div class="loader"></div>
				<span class="text">{{ $t('general.update') }}</span>
			</button>
		</div>
	</div>
	<loader-suspensions-create v-else/>
</template>

<script>
	import ShowContract from  './ShowContract'
	import ChooseContractMultiple from  './ChooseContractMultiple'
	import IconMobile from '../../../../Icons/Mobile'
	import IconCalendar from '../../../../Icons/Calendar'
	import IconRetention from '../../../../Icons/Retention'
    import IconCalculator from '../../../../Icons/Calculator'
	import IconClose from '../../../../Icons/Close'
	import IconArrowBack from '../../../../Icons/ArrowLong'
	import LoaderSuspensionsCreate from '../../../../PagesLoaders/UsersCreate'
    import { required, requiredIf } from 'vuelidate/lib/validators'

	export default {
		components: {
			ShowContract,
			ChooseContractMultiple,
			IconMobile,
			IconCalendar,
			IconRetention,
			IconCalculator,
			IconClose,
			IconArrowBack,
			LoaderSuspensionsCreate
		},
		data() {
			return {
				userData: {},
				loaded: false,
				calendarConfigs: {
					dateFormat: 'yyyy-mm-dd',
					isDatePicker: true,
					isDateRange: false,
					isModal: true,
					isAutoCloseable: true,
					changeMonthFunction: true,
					changeYearFunction: true
				},
				calendarConfigsEndData: {
					dateFormat: 'yyyy-mm-dd',
					isDatePicker: true,
					isDateRange: false,
					isModal: true,
					isAutoCloseable: true,
					limits: {min: '', max : ''}
				},
				error_message: '',
                retentionsOptions: [],
                retentionsBasesOptions: [],
                percentageOptions: [{id: 1, name: this.$t('salary.benefits.percentage'), value: 1}, {id: 2, name: this.$t('salary.benefits.fix_sum'), value: 0}],
                retention: {
                    contractSelected: '',
					selectedRetention: '',
					name: '',
					start_date: {},
					end_date: {},
					is_taxable: 0,
                    retain_from_CAS_CASS: 0,
                    selectedPercentage: {id: 1, name: this.$t('salary.benefits.percentage'), value: 1},
                    value: '',
                    selectedRetentionBase: ''
                }
			}
		},
        validations: {
            retention: {
				contractSelected: {
                    required: requiredIf(function () {
                        return !this.$route.params.id && !this.$route.params.contract_id
                    })
			    },
                selectedRetention: { required },
                name: { required },
				start_date: { required },
                selectedPercentage: { required },
                value: { required }
            }
        },
		async mounted(){
			this.userData = localStorage.userData ? JSON.parse(localStorage.userData) : '';
            await this.getRetentionsOptions();
            await this.getRetentionBases();

			if(this.$route.params.id){
				await this.getRetentionData()
			}
		},
        methods: {
            async getRetentionsOptions() {
                await axios.get(`${this.$auth.user().instance.id}/retentions`).then(({data}) => {
                   this.retentionsOptions = data.data
                }).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				}).finally(() => {
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
            },

            async getRetentionBases() {
                await axios.get(`/retention-bases`).then(({data}) => {
                   this.retentionsBasesOptions = data.data
                }).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
            },

            changeInputValues() {
                this.retention.selectedRetentionBase = ''
                this.retention.name = this.retention.selectedRetention.name
                this.retention.is_taxable = this.retention.selectedRetention.is_taxable
				this.retention.selectedPercentage = this.retention.selectedRetention.is_percentage ? {id: 1, name: this.$t('salary.benefits.percentage'), value: 1} : {id: 2, name: this.$t('salary.benefits.fix_sum'), value: 0}
				this.retention.value = this.retention.selectedRetention.value
                this.retention.retain_from_CAS_CASS = this.retention.selectedRetention.retain_from_CAS_CASS
                if(this.retention.selectedRetention.retention_base_id) {
                    this.retention.selectedRetentionBase = this.retentionsBasesOptions.find(el => el.id === this.retention.selectedRetention.retention_base_id)
                }
            },
			async getRetentionData() {
				await axios.get(`/contract-retentions/${this.$route.params.id}`).then(({data}) => {
                    this.retention.selectedRetention = this.retentionsOptions.find(el => el.id === data.data.retention_id)
                    if(data.data.start_date){
						this.retention.start_date = {
							selectedDate: data.data.start_date,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
                    if(data.data.end_date){
						this.retention.end_date = {
							selectedDate: data.data.end_date,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
                    this.retention.name = data.data.name
                    this.retention.is_taxable = data.data.is_taxable
                    this.retention.retain_from_CAS_CASS = data.data.retain_from_CAS_CASS
                    this.retention.selectedPercentage = this.percentageOptions.find(el => el.value === data.data.is_percentage)
                    this.retention.value = data.data.value
                    this.retention.selectedRetentionBase =this.retentionsBasesOptions.find(el => el.id === data.data.retention_base_id)
                }).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			changeEndDate() {
				this.retention.end_date = {}
				this.calendarConfigsEndData.limits.min = this.retention.start_date.selectedDate
				const lastDay = moment(this.retention.start_date.selectedDate, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD')
				this.calendarConfigsEndData.limits.max = lastDay
			},
			saveAction(type) {
				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = '';

				this.$v.$touch()
                if(!this.$v.$invalid){
					let objData = {}

					if(Object.keys(this.retention.start_date).length){
						objData.start_date = this.retention.start_date.selectedDate
					}
					if(Object.keys(this.retention.end_date).length){
						objData.end_date = this.retention.end_date.selectedDate
					}
                    objData.retention_id = this.retention.selectedRetention ? this.retention.selectedRetention.id : ''
                    objData.name = this.retention.name
                    objData.is_taxable = this.retention.is_taxable
                    objData.retain_from_CAS_CASS = this.retention.retain_from_CAS_CASS
                    objData.is_percentage = this.retention.selectedPercentage ? this.retention.selectedPercentage.value : ''
                    objData.value = this.retention.value
                    objData.retention_base_id = this.retention.selectedRetentionBase ? this.retention.selectedRetentionBase.id : ''
					if(this.$route.params.id) {
						if(type == 'create'){
							// this.createRetention(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
						}else if(type == 'update'){
							this.updateRetention(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
						}
					} else {
						var selectedContracts = this.retention.contractSelected.length ? this.retention.contractSelected.map(function(a) {return a.id;}) : ''
						if(selectedContracts.length) { objData.contract_ids = selectedContracts }
						this.createSalaryRetention(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			createSalaryRetention(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post(`${this.$auth.user().instance.id}/contract-retentions/store/multiple`, objData)
				.then(({data}) => {
                    btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							this.$router.push({ name: 'retentions' })
						}, 1000);
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
							this.error_message = Object.values(error.response.data.errors).join('')
							if(error.response.data.errors.popup) {
								this.$root.$emit('open_modal', 'error', {data: error.response.data.errors.popup[0]}, false);
							}
						}, 1000)
					}, 300)
				})
			},
			updateRetention(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader) {
				axios.patch(`/contract-retentions/${this.$route.params.id}`, objData)
				.then(({data}) => {
                    btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							this.$router.push({ name: 'retentions' })
						}, 1000);
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
							this.error_message = Object.values(error.response.data.errors).join('')
							if(error.response.data.errors.popup) {
								this.$root.$emit('open_modal', 'error', {data: error.response.data.errors.popup[0]}, false);
							}
						}, 1000)
					}, 300)
				})
			}
        }
	}
</script>